import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";

import Header from "./fragments/common/Header";
import Footer from "./fragments/common/Footer";
import Home from "./pages/Home";
import ShopsRetailSigns from "./pages/signselection/ShopsRetailSigns";

// About Us
import OurStory from "./pages/aboutus/OurStory";
import MeetTheTeam from "./pages/aboutus/MeetTheTeam";
import ContactUs from "./pages/aboutus/ContactUs";

// What We Do
import SignageSolution from "./pages/whatwedo/SignageSolution";
import CaseStudies from "./pages/whatwedo/CaseStudies";
import News from "./pages/whatwedo/News";
import SinglePost from "./pages/whatwedo/SinglePost";

// Top level pages
import QuoteSiteVisit from "./pages/QuoteSiteVisit";
import SignBuyersGuide from "./pages/SignBuyersGuide";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Locations from "./pages/Locations";
import LocationSingle from "./pages/LocationSingle";
import ProductSingle from "./pages/ProductSingle";

import locations from "./data/locations";
import locationsGallery from "./data/locationsGallery";

import FontFaceObserver from "fontfaceobserver";

// Check if we're pre-rendering
export const isPrerender = navigator.userAgent === "ReactSnap";
const originalPath = "https://yellowsigns.co.uk";

const App = () => {
  const [posts, setPosts] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const loadContent = async () => {
      // Fetch posts from WP
      try {
        const res = await axios.get(
          `https://blog.yellowsigns.co.uk/wp-json/wp/v2/posts?per_page=30`
        );
        setPosts(res.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }

      // LiveChat script setup
      const livechatScript = document.createElement("script");
      livechatScript.innerHTML = `window.__lc = window.__lc || {};
     window.__lc.license = 13787559;
     (function (n, t, c) {
       function i(n) {
         return e._h ? e._h.apply(null, n) : e._q.push(n);
       }
       var e = {
         _q: [],
         _h: null,
         _v: "2.0",
         on: function () {
           i(["on", c.call(arguments)]);
         },
         once: function () {
           i(["once", c.call(arguments)]);
         },
         off: function () {
           i(["off", c.call(arguments)]);
         },
         get: function () {
           if (!e._h)
             throw new Error("[LiveChatWidget] You can't use getters before load.");
           return i(["get", c.call(arguments)]);
         },
         call: function () {
           i(["call", c.call(arguments)]);
         },
         init: function () {
           var n = t.createElement("script");
           n.async = !0,
             n.type = "text/javascript",
             n.src = "https://cdn.livechatinc.com/tracking.js",
             t.head.appendChild(n);
         },
       };
       !n.__lc.asyncInit && e.init(),
         (n.LiveChatWidget = n.LiveChatWidget || e);
     })(window, document, [].slice);`;
      // setTimeout(() => {
      //   document.body.appendChild(livechatScript);
      // }, 2000);
    };

    loadContent();
  }, []);
  // console.log(locationsGallery?.map(l => `${l?.metaTitle} , ${l?.name}`))
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
      </Helmet>

      <Router>
        <Header />
        <Switch>
          <Route exact path="/sign-buyers-guide">
            <Helmet>
              <title>Your Essential Sign Buyer's Guide | Yellow Signs</title>
              <meta
                name="description"
                content={
                  "Navigate the world of signage with our comprehensive buyer's guide. Yellow Signs provides insights to help you make informed decisions."
                }
              />
              <link
                rel="canonical"
                href={`${originalPath}/sign-buyers-guide`}
              />
            </Helmet>
            <SignBuyersGuide />
          </Route>
          {/* Location Gallery Pages */}
          {locationsGallery.map((page, index) => (
            <Route exact path={page?.href} key={index}>
              <Helmet>
                <title>{page?.metaTitle}</title>
                <meta name="description" content={page?.metaDescription} />
                <link rel="canonical" href={`${originalPath}${page?.href}`} />
              </Helmet>
              <ProductSingle page={page} />
            </Route>
          ))}

          {/* About us */}
          <Route exact path="/our-story">
            <Helmet>
              <title>Discover the Journey of Yellow Signs | Our Story</title>
              <meta
                name="description"
                content="Learn about Yellow Signs' history, mission, and values. See how our passion for signage has driven our success in London and beyond."
              />
              <link rel="canonical" href={`${originalPath}/our-story`} />
            </Helmet>
            <OurStory />
          </Route>
          <Route exact path="/meet-the-team">
            <Helmet>
              <title>Meet the Experts Behind Yellow Signs | Our Team</title>
              <meta
                name="description"
                content="Get to know the dedicated professionals at Yellow Signs. Our team's expertise ensures top-quality signage solutions for your business."
              />
              <link rel="canonical" href={`${originalPath}/meet-the-team`} />
            </Helmet>
            <MeetTheTeam />
          </Route>
          <Route exact path="/contact-us">
            <Helmet>
              <title>
                Contact Yellow Signs | London's Premier Signage Specialists
              </title>
              <meta
                name="description"
                content="Get in touch with Yellow Signs for all your signage needs. Call us at 020 7350 1567 or email info@yellowsigns.co.uk."
              />
              <link rel="canonical" href={`${originalPath}/contact-us`} />
            </Helmet>
            <ContactUs />
          </Route>

          {/* What We Do */}
          <Route exact path="/signage-solution">
            <Helmet>
              <title>
                Complete Signage Solutions Tailored to Your Needs | Yellow Signs
              </title>
              <meta
                name="description"
                content="Explore our range of signage services designed to elevate your brand. From concept to installation, Yellow Signs provides end-to-end solutions."
              />
              <link rel="canonical" href={`${originalPath}/signage-solution`} />
            </Helmet>
            <SignageSolution />
          </Route>
          <Route exact path="/case-studies">
            <Helmet>
              <title>
                Success Stories: Transformative Signage Projects | Yellow Signs
              </title>
              <meta
                name="description"
                content="Explore our case studies to see how Yellow Signs has enhanced businesses with bespoke signage solutions across London."
              />
              <link rel="canonical" href={`${originalPath}/case-studies`} />
            </Helmet>
            <CaseStudies slug="/case-studies" posts={posts} />
          </Route>
          <Route exact path="/news">
            <Helmet>
              <title>
                Latest News & Updates from London's Signage Experts | Yellow
                Signs
              </title>
              <meta
                name="description"
                content="Stay informed with the latest news, updates, and insights from Yellow Signs, your trusted signage specialists in London."
              />
              <link rel="canonical" href={`${originalPath}/news`} />
            </Helmet>
            <News slug="/news" posts={posts} />
          </Route>

          {/* Blog Pages Single */}
          {posts.map((item, index) => (
            <Route path={`/${item.slug}`} key={index}>
              <Helmet>
                <title>{item?.title?.rendered} | Yellow Signs</title>
                <meta
                  name="description"
                  content={item?.content?.rendered?.substring(4, 200)}
                />
                <link rel="canonical" href={`${originalPath}/${item?.slug}`} />
              </Helmet>
              <SinglePost
                slug={item.slug}
                title={item.title.rendered}
                date={item.date}
                img={item.jetpack_featured_media_url}
                content={item.content.rendered}
                category={item.categories[0] === 2 ? "News" : "Case Studies"}
                recentPosts={posts}
              />
            </Route>
          ))}

          {/* Location Pages Single */}
          <Route exact path="/locations">
            <Helmet>
              <title>
                Comprehensive Signage Services Across London | Yellow Signs
              </title>
              <meta
                name="description"
                content="Yellow Signs offers expert signage solutions throughout London and the surrounding areas. Find out if we serve your location today."
              />
              <link rel="canonical" href={`${originalPath}/locations`} />
            </Helmet>
            <Locations />
          </Route>
          {locations.map((item, index) => (
            <Route path={item.href} key={index}>
              <Helmet>
                <title>{item?.metaTitle}</title>
                <meta name="description" content={item?.metaDescription} />
                <link rel="canonical" href={`${originalPath}${item?.href}`} />
              </Helmet>
              <LocationSingle {...item} />
            </Route>
          ))}

          {/* Home & Top Level Pages */}
          <Route exact path="/quote-site-visit">
            <Helmet>
              <title>
                Request a Free Quote & Site Visit | Yellow Signs London
              </title>
              <meta
                name="description"
                content="Need a signage solution? Request a free, no-obligation quote and site visit from Yellow Signs today."
              />
              <link rel="canonical" href={`${originalPath}/quote-site-visit`} />
            </Helmet>
            <QuoteSiteVisit />
          </Route>
          <Route exact path="/privacy-policy">
            <Helmet>
              <title>Privacy Policy | Yellow Signs</title>
              <meta
                name="description"
                content="Learn how Yellow Signs protects your personal information in our comprehensive privacy policy."
              />
              <link rel="canonical" href={`${originalPath}/privacy-policy`} />
            </Helmet>
            <PrivacyPolicy />
          </Route>
          <Route exact path="/terms-and-conditions">
            <Helmet>
              <title>Terms & Conditions | Yellow Signs</title>
              <meta
                name="description"
                content="Review the terms and conditions governing the use of Yellow Signs' services and website."
              />
              <link
                rel="canonical"
                href={`${originalPath}/terms-and-conditions`}
              />
            </Helmet>
            <TermsConditions />
          </Route>
          <Route exact path="/">
            <Helmet>
              <html lang="en" />
              <title>
                Yellow Signs | London's Leading Signage & Graphics Specialists
              </title>
              <meta
                name="description"
                content="Discover premium signage and graphics solutions with Yellow Signs, a family-owned business based in Battersea, London."
              />
              <meta name="robots" content="index, follow" />
              <meta
                property="og:title"
                content="Yellow Signs | London's Leading Signage & Graphics Specialists"
              />
              <meta
                property="og:description"
                content="Discover premium signage and graphics solutions with Yellow Signs, a family-owned business based in Battersea, London."
              />
              <meta property="og:type" content="website" />
              <link rel="canonical" href={`${originalPath}/`} />
            </Helmet>
            <Home />
          </Route>

          {/* Old Route Need to be redirected */}
          <Route exact path="/contact">
            <Redirect to="/contact-us" />
          </Route>

          <Route exact path="/locations/our-story">
            <Redirect to="/locations" />
          </Route>

          <Route exact path="/locations/meet-the-team">
            <Redirect to="/locations" />
          </Route>

          <Route exact path="/basket">
            <Redirect to="/" />
          </Route>

          <Route exact path="/products">
            <Redirect to="/" />
          </Route>

          <Route exact path="/cut-vinyl-">
            <Redirect to="/cut-vinyl-graphics" />
          </Route>

          <Route exact path="/materials">
            <Redirect to="/" />
          </Route>

          <Route
            exact
            path="/digital-wallpaper-restaurant-and-takeaway-tinseltown"
          >
            <Redirect to="/case-studies" />
          </Route>

          <Route exact path="/digital-wallpaper-restaurant">
            <Redirect to="/digital-wallpaper" />
          </Route>
          {/* Fallback */}
          <Route path="*">
            <div style={{ padding: "10rem", textAlign: "center" }}>
              <Loader
                type="Grid"
                color="#ffd800"
                height={80}
                width={80}
                timeout={4000}
              />
            </div>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default App;
