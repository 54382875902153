import React, { Component } from "react";
import ProgressiveImage from "react-progressive-image";

// import { ReactComponent as Logo } from "../images/yellow-signs-logo.svg";

import Container from "react-bootstrap/Container";
import device from "../fragments/common/device";
import styled from "styled-components";

const height = "32rem";
const heightMd = "16rem";

const TealWrapper = styled.div`
  background: var(--ys-halft);
  height: ${height};
  // margin-top: 1rem;
  @media ${device.md} {
    height: ${heightMd};
  }
`;

const YellowLine = styled.div`
  width: 100%;
  background: var(--ys-halfy);
  position: absolute;
  margin-top: 5rem;
  z-index: 0;
  @media ${device.md} {
    margin-top: 1.2rem;
  }
`;

const SkewBox = styled.div`
  background: var(--ys-yellow);
  width: 100%;
  height: ${height};
  width: 9rem;
  position: absolute;
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-5deg, 0deg);
  margin: 2rem 0 0 59rem;
  @media ${device.md} {
    display: none;
    height: ${heightMd};
  }
`;

const TitleWrapper = styled.h1`
  background: var(--ys-teal);
  padding: 5rem 0 0 2rem;
  display: inline-block;
  float: left;
  height: ${height};
  position: absolute;
  z-index: 2;
  text-transform: uppercase;
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-6deg, 0deg);
  @media ${device.md} {
    height: ${heightMd};
    padding: 4rem 0 0 2rem;
    width: 100%;
    position: relative;
    height: ${(props) => props.height};
  }
`;

const LineOne = styled.span`
  display: block;
  color: white;
  font-weight: 300;
  @media ${device.md} {
    font-size: 2rem;
  }
`;

const LineTwo = styled.span`
  display: block;
  color: var(--ys-yellow);
  font-family: Alternate, "sans-serif";
  font-size: 14rem;
  margin-top: -3rem;
  @media ${device.md} {
    font-size: 5rem;
    margin-top: -0.5rem;
  }
`;

const LineThree = styled.span`
  display: block;
  color: white;
  width: 30rem;
  font-weight: 300;
  margin-top: -2.7rem;
  @media ${device.md} {
    font-size: 2rem;
    margin-top: -1rem;
    width: 100%;
  }
`;

const LineFour = styled.span`
  display: block;
  color: var(--ys-yellow);
  width: 30rem;
  font-family: Alternate, "sans-serif";
  font-size: 4rem;
  margin-top: -0.4rem;
  @media ${device.md} {
    font-size: 3rem;
    width: 100%;
  }
`;

const ImgWrapper = styled.div`
  height: ${height};
  overflow: hidden;
  position: absolute;
  background: white;
  z-index: 1;
  margin-left: 15rem;
  clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
  @media ${device.md} {
    height: ${heightMd};
  }
`;

// const Img = styled.img`
//   width: 53rem;
//   @media ${device.md} {
//     display: none;
//   }
// `;

class PageBannerA extends Component {
  render() {
    return (
      <div>
        <TealWrapper>
          <YellowLine style={{ height: "13.5rem" }} />
          <Container>
            <TitleWrapper
              // style={
              //   this.props.width
              //     ? { width: this.props.width }
              //     : { width: "17rem" }
              // }
              className={this.props.line4 ? "pt2rem" : "TitleWrapper"}
            >
              <LineOne>{this.props.line1}</LineOne>
              <LineTwo>{this.props.line2}</LineTwo>
              {this.props.line3 ? (
                <LineThree> {this.props.line3} </LineThree>
              ) : null}
              {this.props.line4 ? (
                <LineFour> {this.props.line4} </LineFour>
              ) : null}
            </TitleWrapper>

            <ImgWrapper>
              {/* <Img src={this.props.img} alt={this.props.line2} /> */}
              <ProgressiveImage
                src={this.props.img}
                placeholder={this.props.imgTiny}
              >
                {(src, loading) => (
                  <img
                    src={src}
                    alt={this.props.line2}
                    className="page-banner-a-image"
                    style={{
                      objectPosition: "0px -80px",
                      filter: loading ? "blur(10px)" : "blur(0)",
                      // transition: ".1s",
                      width: "53rem",
                    }}
                  />
                )}
              </ProgressiveImage>
            </ImgWrapper>

            <SkewBox />
          </Container>
        </TealWrapper>
      </div>
    );
  }
}

export default PageBannerA;
