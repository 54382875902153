import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import device from "./device";
import Button from "../common/Button";
import LogoSlider from "./LogoSlider";
import LocationList from "../locations/LocationList";
import ContactForm from "./ContactForm";
import TestimonialsSlider from "../TestimonialsSlider";

const footerMenu = [
  { page: "Terms & Conditions", link: "/terms-and-conditions" },
  { page: "Privacy Policy", link: "/privacy-policy" },
  { page: "Sign Buyers Guide", link: "/sign-buyers-guide" },
  { page: "Case Studies", link: "/case-studies" },
  { page: "News", link: "/news" },
  { page: "Contact Us", link: "/contact-us" },
];

const MenuItem = styled.div`
  color: white;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 300;
  padding-right: 1rem;

  @media ${device.md} {
    display: block;
    padding-right: 0;
    margin-bottom: 6px;
  }

  a {
    span {
      transition: color 0.3s;
      &:hover {
        color: var(--ys-yellow) !important;
      }
    }
  }
`;

const MidLogos = styled.div`
  text-align: center;
`;

const MidLogosImg = styled.img`
  width: 23rem;
  height: auto;
  @media ${device.md} {
    width: 100%;
  }
`;

const GoogleReviewsLink = styled.a`
  cursor: pointer;
`;

const GoogleReviewsLogo = styled.img`
  width: 8rem;
  display: block;
  margin: 0 auto;
  filter: contrast(0.5) opacity(0.75);
`;

const ItemSeperator = styled.span`
  margin-right: 1rem;
  color: var(--ys-yellow);
  @media ${device.md} {
    color: var(--ys-black);
    display: none;
  }
`;

const FooterMenu = styled.div`
  background: var(--ys-black);
  padding: 1rem 0 1rem 0;
`;

const Credentials = styled.p`
  text-align: center;
  color: var(--ys-grey);
  padding: 0.25rem;
  opacity: 0.7;
`;

const FormAndClients = styled.div`
  background-color: var(--ys-black);
  padding-top: 56px;
  color: var(--ys-white);
  overflow: hidden;
`;

const Wrapper = styled.footer`
  p {
    font-weight: 400;
  }
`;

const Widget = styled.div`
  background: var(--ys-black);
  padding: 56px 0;
  color: var(--ys-white);
  overflow: hidden;
  text-align: center;
`;
const Footer = () => {
  const location = useLocation();

  const isProductSingle = location.pathname === "/product-single";

  return (
    <Wrapper>
      <FormAndClients>
        <Container>
          <ContactForm />
        </Container>
        <LogoSlider />
      </FormAndClients>
      <Widget>{/* <Container>Place for widget</Container> */}</Widget>
      {isProductSingle && (
        <div style={{ background: "#E6F7F6", marginBottom: "18px" }}>
          <LocationList />
        </div>
      )}
      <div>
        <div>
          <Container style={{ color: "var(ys-grey)", margin: "30px auto" }}>
            <TestimonialsSlider />
            <MidLogos>
              <GoogleReviewsLink
                href="https://www.google.com/maps/place/Yellow+Signs/@51.4657942,-0.1808475,15z/data=!4m7!3m6!1s0x0:0x8fe81c185d8ac17!8m2!3d51.4657942!4d-0.1808475!9m1!1b1"
                target="_blank"
              >
                <GoogleReviewsLogo
                  loading="lazy"
                  src="/img/common/google-5-star-reviews.png"
                  alt="logos"
                  width={144}
                  height={72}
                />
              </GoogleReviewsLink>
              <MidLogosImg
                src="/img/common/lower-footer-logos.jpg"
                alt="logos"
                width={414}
                height={81}
                loading="lazy"
              />
            </MidLogos>
            <Credentials>
              Yellow Signs Ltd | Unit 5 River Reach, Gartons Way, Battersea,
              London SW11 3SX | Registered in England & Wales 07898949
            </Credentials>
          </Container>
        </div>
        <FooterMenu className="text-center">
          <Container>
            {footerMenu.map((item, index) => (
              <MenuItem key={index}>
                <Link to={item.link}>
                  <span style={{ color: "white" }}>
                    {item.page === "Terms & Conditions" ? null : (
                      <ItemSeperator>|</ItemSeperator>
                    )}
                    {item.page}
                  </span>
                </Link>
              </MenuItem>
            ))}
          </Container>
        </FooterMenu>
      </div>
    </Wrapper>
  );
};

export default Footer;
