import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const images = [
  "/img/product-single/slider-img-1.png",
  "/img/product-single/slider-img-2.png",
  "/img/product-single/slider-img-3.png",
  "/img/product-single/slider-img-4.png",
];

const Wrapper = styled.div`
  position: relative;
  pointer-events: none;
  background: rgba(2, 175, 164, 0.1);
  padding: 24px 0;
  margin-bottom: 80px;

  .slick-list {
    max-height: 450px;
  }
`;

const Slide = styled.div`
  height: 450px;
  overflow: hidden;
  padding: 0 25px;

  img {
    width: 100%;
    height: 100%
    object-position: center;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    height: 200px;
    padding: 0 10px;
    img {
      width:  100%;
      height: 100%;
    }
  }
`;

const ImageSlider = (props) => {
  const settings = {
    // dots: false,
    // infinite: true,
    // speed: 10000,
    // slidesToShow: 4,
    // slidesToScroll: 1,
    // arrows: false,
    // autoplay: true,
    // draggable: false,
    // autoplaySpeed: 0,
    // easing: "linear",
    // cssEase: "linear",
    // variableWidth: true,
    slidesToShow: 2,
    autoplay: true,
    dots: false,
    infinite: true,
    easing: "linear",
    cssEase: "linear",
    autoplaySpeed: 0,
    speed: 5000,
    arrows: false,
    // variableWidth: true,
  };
  return (
    <Wrapper>
      <Slider {...settings}>
        {(props?.gallery || [])?.map((slide, index) => {
          return (
            <Slide key={index}>
              <img
                width={537}
                height={500}
                src={slide?.img}
                alt={slide?.title}
              />
            </Slide>
          );
        })}
      </Slider>
    </Wrapper>
  );
};

export default ImageSlider;
