import React, { Component } from "react";
import Helmet from "react-helmet";
import PageBannerA from "../../fragments/PageBannerA";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";

import QuoteForm from "../../fragments/QuoteForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import device from "../../fragments/common/device";
import HTMLReactParser from "html-react-parser";

const Title = styled.h3`
  color: var(--ys-teal);
`;

const Text = styled.p``;

const Accent = styled.span`
  font-weight: 600;
  color: var(--ys-teal);
`;

const WhatsappImgGreen = styled.img`
  height: 1.1rem;
  margin-right: 0.25rem;
`;

const socialImgWhatsapp = {
  color: "var(--ys-teal)",
  width: "1rem",
  height: "1rem",
};

const BannerImage = styled.img`
  width: 85%;
  @media ${device.md} {
    width: 100%;
  }
`;

const ContactContainer = styled(Container)`
  margin: 6rem auto;
  @media ${device.sm} {
    margin: 2rem auto;
  }
`;

const ContactRow = styled(Row)`
  @media ${device.sm} {
    flex-direction: column;
    gap: 40px;
  }
`;

const MapWithMarker = ({ text }) => (
  <img
    src="/img/contactus/y-pin.png"
    alt="Yellow Signs"
    style={{ width: "3.8rem", borderRadius: 5 }}
  ></img>
);

class ContactUs extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  static defaultProps = {
    center: {
      lat: 51.4659139,
      lng: -0.1829282,
    },
    zoom: 14,
  };

  render() {
    return (
      <div>

        <PageBannerA
          line1="Get in"
          line2="Touch"
          img="/img/contactus/map.jpg"
          imgTiny="/img/contactus/map-tiny.jpg"
          width="23.5rem"
        />
        <ContactContainer
        // style={{
        //   margin: "6rem auto",
        // }}
        >
          <ContactRow>
            <Col lg={8}>
              <BannerImage
                src="/img/contactus/ys-building.jpg"
                // style={{ width: "85%", margin: "0 auto" }}
                alt="Yellow Signs Building"
              />
            </Col>
            <Col lg={4}>
              <Title>HQ & PRODUCTION CENTRE</Title>
              <Text>
                Unit 5, River Reach, Gartons Way, Battersea, London SW11 3SX
              </Text>
              <Text>
                <Accent>t:</Accent> <a href="tel:02073501567">020 7350 1567</a>
              </Text>
              <Text>
                <Accent>e:</Accent>{" "}
                <a href="mailto:info@yellowsigns.co.uk" id="email">
                  info@yellowsigns.co.uk
                </a>
              </Text>
              <Text>
                <WhatsappImgGreen src="/img/common/whatsapp.svg" />
                <a href="https://wa.me/4407539269999" id="email">
                  {" "}
                  075 3926 9999{" "}
                </a>
                (Whatsapp Photos)
              </Text>
              <Text>
                <Accent>Opening Times</Accent>
              </Text>
              <Row>
                <Col span={2}>
                  <Text>
                    Monday - Friday
                    <br />
                    Saturday:
                    <br />
                    Sunday:
                  </Text>
                </Col>
                <Col span={2}>
                  <Text>
                    8am – 5pm
                    <br />
                    Closed
                    <br />
                    Closed
                  </Text>
                </Col>
              </Row>
            </Col>
          </ContactRow>
        </ContactContainer>

        <QuoteForm />

        {navigator.userAgent !== "ReactSnap" && (
          <Container>
            <Row>
              <Col>
                <div
                  style={{ height: "30rem", width: "100%", marginBottom: 100 }}
                >
                  {/* <GoogleMapReact
                  bootstrapURLKeys={{
                    // key: "AIzaSyDVvF3bZX74Y0Ltl5LPzjBWa-Seb5FFDt8",
                  }}
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                >
                  <MapWithMarker lat={51.465985} lng={-0.1809263} />
                </GoogleMapReact> */}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11359.642402931691!2d-0.19134672709360492!3d51.46179435771299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876058437a3a725%3A0x13e66c1fbc6883b0!2s5%20River%20Reach%2C%20Gartons%20Way%2C%20London%20SW11%203SX!5e1!3m2!1sen!2suk!4v1726825811205!5m2!1sen!2suk"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

export default ContactUs;
