import React, { Component } from "react";
import device from "../common/device";
import styled from "styled-components";
import Col from "react-bootstrap/Col";

const Title = styled.h3`
  top: 15%;
  color: var(--ys-teal);
  width: 100%;
  text-align: left;
  pointer-events: none;
  margin-top: 0.65rem;
  margin-bottom: 1.5rem;
  font-size: 20px !important;
  line-height: 28px;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  opacity: 0;
  text-align: center;
  pointer-events: none;
  transition: 0.3s;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
`;

const Loupe = styled.img`
  width: 4rem;
  /* margin-top: 40%; */
`;

const ImgWrap = styled.div`
  height: calc(350rem / 18);
  background-size: cover !important;
  background-position: ${(props) => props.alignment} !important;
  cursor: pointer;
`;

const Wrapper = styled.div`
  // padding-bottom: 1.5rem;
  position: relative;
  &:hover ${Overlay} {
    opacity: 0.65;
  }
`;

const ModalWrapper = styled.div`
  background: #000000a1;
  position: fixed;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s;
  z-index: 4;
  pointer-events: none;
  text-align: center;
`;

const ModalImg = styled.img`
  height: 80vh;
  margin-top: 5%;
  object-fit: contain;
  @media ${device.md} {
    height: auto;
    aspect-ratio: 1;
    width: 100%;
    margin-top: 40%;
    padding: 20px;
  }
`;

const ModalTitle = styled.h3`
  color: white;
  @media ${device.md} {
    font-size: 1.5rem !important;
  }
`;

class ServicesGalleryImg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      pointerEvents: "none",
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalOpen() {
    this.setState({
      opacity: 1,
      pointerEvents: "all",
    });
  }

  handleModalClose() {
    this.setState({
      opacity: 0,
      pointerEvents: "none",
    });
  }

  render() {
    return (
      <Col lg={4} sm={12}>
        <Wrapper>
          <Overlay>
            <Loupe
              width={72}
              height={69}
              alt="loupe"
              src="/img/signselection/loupe-ys.svg"
            />
          </Overlay>
          <ImgWrap
            style={{ background: `url(${this.props.img})` }}
            onClick={this.handleModalOpen}
            alignment={this.props.alignment ? this.props.alignment : "center"}
          />
        </Wrapper>
        <Title>{this.props.title.replace(/_+/g, " ")}</Title>
        <ModalWrapper
          style={{
            opacity: this.state.opacity,
            pointerEvents: this.state.pointerEvents,
          }}
          onClick={this.handleModalClose}
        >
          <ModalImg
            src={this.props.img}
            alt={this.props.title}
            width={780}
            height={667}
          />
          <ModalTitle>{this.props.title.replace(/_+/g, " ")}</ModalTitle>
        </ModalWrapper>
      </Col>
    );
  }
}

export default ServicesGalleryImg;
