import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import device from "../../fragments/common/device";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

const DownloadWrap = styled.div`
	background: rgb(8, 175, 164);
	background: linear-gradient(
		38deg,
		rgba(8, 175, 164, 1) 0%,
		rgba(8, 175, 164, 0.6050129524466037) 100%
	);
	margin: 4rem 0;
	font-family: Alternate, "sans-serif";
	@media ${device.md} {
		display: none;
	}
`;

const DownloadBox = styled.div`
	background: var(--ys-yellow);
	color: black;
	position: absolute;
	margin-left: 20%;
	padding: 0.4rem 1.2rem;
	margin-top: -0.4rem;
	font-size: 2rem;
	font-style: italic;
	clip-path: polygon(7% 0, 100% 0%, 93% 101%, 0% 101%);
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		background: var(--ys-yellow-lt);
	}
`;

const DownloadTxt = styled.div`
	color: white;
	font-style: italic;
	font-size: 2rem;
	margin-left: 35%;
	display: inline-block;
	@media ${device.sm} {
		margin-left: 8%;
	}
`;

const CatalogueImg = styled.img`
	position: absolute;
	margin-top: -3rem;
	@media ${device.sm} {
		display: none;
	}
`;

class ShopsRetailSigns extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				{/* <Helmet>
					<title>Shop Signs in London & Shop Signs for Business</title>
					<meta
						name="description"
						content="Shop Signs for Businesses in London - We fabricate in-house so we can guarantee super-fast turnaround times and installations of shop signs in London"
					/>
				</Helmet> */}
				<HeaderSimple
					heading="Shop & Retail Signs"
					subtitle="SHOP SIGNS CAN EFFECTIVELY DRIVE FOOTFALL AND ARE AVAILABLE IN VARIOUS MATERIALS AND STYLES – FROM ALUMINIUM COMPOSITE AND STAINLESS STEEL TO ACRYLIC AND WOOD. SHOP SIGN TRAYS ARE OFTEN MADE FROM ALUMINIUM OR ALUMINIMUM COMPOSITE and available in a range of colours."
				/>

				<Container style={{ marginTop: "-2rem" }}>
					<Row>
						<Col>
							<DownloadWrap>
								{/* <Link to="sign-buyers-guide"> */}
								<a href="/downloads/Yellow_Signs_Shop_Sign_Buyers_Guide.pdf" target="_blank">
									<DownloadBox>DOWNLOAD</DownloadBox>
								</a>
								{/* </Link> */}

								<DownloadTxt style={{ transition: "1s" }}>
									OUR SHOP SIGN BUYERS GUIDE HERE!
								</DownloadTxt>

								<CatalogueImg
									src={"/img/home/buyers-guide.png"}
									alt={"Yellow Print Buyers Guide"}
								/>
							</DownloadWrap>
						</Col>
					</Row>

					<Row>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/StainlessSteelAcrylicIlluminatedLetters.jpg"
							title="Stainless Steel & Acrylic Illuminated Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/halo-illuminated-3d-built-up-letters.jpg"
							title="Halo Illuminated 3D Built Up Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/illuminated-aluminium-pushed-through-acrylic-sign-tray.jpg"
							title="Illuminated Aluminium Pushed Through Acrylic Sign Tray"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/aluminium-composite-panel-faced-with-vinyl.jpg"
							title="Aluminium Composite Panel Faced with Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/aluminium-composite-sign-trays--window-graphics.jpg"
							title="Aluminium Composite Sign Trays & Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/aluminium-composite-sign-tray-trough-light--window-graphics.jpg"
							title="Aluminium Composite Sign Tray, Trough Light & Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/bungee-pvc-banner--aluminium-composite-trays.jpg"
							title="Bungee PVC Banner & Aluminium Composite Trays"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/aluminium-illuminated-sign-trays-with-acrylic-letters.jpg"
							title="Aluminium Illuminated Sign Trays with Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/3DBuiltUpRimReturnFrontIlluminated.jpg"
							title="3D Built-Up Rim & Return Front Illuminated"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/BevelCutAcrylicLetterswithTroughLighting.jpg"
							title="Bevel Cut Acrylic Letters with Trough Lighting"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/BrushedGoldStainlessSteel3DBuiltUpLetters.jpg"
							title="Brushed Gold Stainless Steel 3D Built-Up Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/CutVinyl.jpg"
							title="Cut Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FlatCutAcrylicTroughLighting.jpg"
							title="Flat Cut Acrylic & Trough Lighting"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FlatCutAcrylicLetters.jpg"
							title="Flat Cut Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FlatCutAcrylicLetters2.jpg"
							title="Flat Cut Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FlatCutAcrylicLettersonWood.jpg"
							title="Flat Cut Acrylic Letters on Wood"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FlatCutAcrylicStandOffLetters.jpg"
							title="Flat Cut Acrylic Stand-Off Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FlatCutBlueBrushedStainlessSteelLetters.jpg"
							title="Flat Cut Blue Brushed Stainless Steel Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FlatCutSolidWood.jpg"
							title="Flat Cut Solid Wood"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FlatCutPowderCoatedAcrylicLetters.jpg"
							title="Flat Cut Powder Coated Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/InternallyIlluminatedAcrylicLetters.jpg"
							title="Internally Illuminated Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/PushedThroughAcrylicIlluminatedCompositeAluminiumTray.jpg"
							title="Pushed-Through Acrylic Illuminated Composite Aluminium Tray"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/PushedThroughAcrylicIlluminatedCompositeAluminiumTray2.jpg"
							title="Pushed-Through Acrylic Illuminated Composite Aluminium Tray"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/PushedThroughAcrylicIlluminatedCompositeAluminiumTray3.jpg"
							title="Pushed-Through Acrylic Illuminated Composite Aluminium Tray"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/hydrofit.jpg"
							title="Pushed-Through Acrylic Illuminated Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/3d-built-up-letters.jpg"
							title="3D Built Up Letters"
						/>

						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/pushed-though-acrylic-letters-faced-with-vinyl.jpg"
							title="Pushed Through Acrylic Letters Faced with Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/WET_COATED_ACRYLIC_LETTERS_FROSTING_AND_WINDOW_GRAPHICS.jpg"
							title="WET COATED ACRYLIC LETTERS, FROSTING AND WINDOW GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/ALUMINIUM_COMPOSITE_SIGN_TRAYS_WITH_FULL_COLOUR_PRINT.jpg"
							title="ALUMINIUM COMPOSITE SIGN TRAYS WITH FULL COLOUR PRINT"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS.jpg"
							title="ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO.jpg"
							title="FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/STOCK_BLUE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_PUSHED-THROUGH_ACRYLIC_LETTERS_&_MATCHING_PROJECTING_SIGN.jpg"
							title="STOCK_BLUE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_PUSHED-THROUGH_ACRYLIC_LETTERS_&_MATCHING_PROJECTING_SIGN"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY__PROJECTING_SIGN.jpg"
							title="PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY_+_PROJECTING_SIGN"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_PRINTED_SIGN_TRAY.jpg"
							title="PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_PRINTED_SIGN_TRAY"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS.jpg"
							title="WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FULL_COLOUR_&_WHITE_CUT_VINYL_WINDOW_GRAPHICS.jpg"
							title="FULL_COLOUR_&_WHITE_CUT_VINYL_WINDOW_GRAPHICS"
						/>
						{/* <ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/ALUMINIUM_COMPOSITE_SIGN_TRAYS_WITH_FULL_COLOUR_PRINT.jpg"
							title="ALUMINIUM_COMPOSITE_SIGN_TRAYS_WITH_FULL_COLOUR_PRINT"
						/> */}
						{/* <ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg"
							title="WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS"
						/> */}
						{/* <ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/FULL_COLOUR_PRINTED_CONTOUR_CUT_WINDOW_GRAPHICS.jpg"
							title="FULL_COLOUR_PRINTED_CONTOUR_CUT_WINDOW_GRAPHICS"
						/> */}
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY.jpg"
							title="PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/PUSHED-THROUGH_ACRYLIC_INTERNALLY_ILLUMINATED_SIGN_TRAY_WITH_LETTERS_FACED_IN_VINYL.jpg"
							title="PUSHED-THROUGH_ACRYLIC_INTERNALLY_ILLUMINATED_SIGN_TRAY_WITH_LETTERS_FACED_IN_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/WET_COATED_10MM_ACRYLIC_LETTERS_WITH_25MM_BLACK_FOAM_PROJECTING_SIGN.jpg"
							title="WET_COATED_10MM_ACRYLIC_LETTERS_WITH_25MM_BLACK_FOAM_PROJECTING_SIGN"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									In such a digital age, surrounded by modern technological ways of marketing
									products to consumers, often businesses need to seek more fool-proof and
									traditional forms of advertisement. Whilst we know social media can be powerful,
									sometimes going back to basics with visual marketing is the winner. As one of the
									oldest and most effective marketing models for the high street, shop and retail
									signs are a fantastic form of around-the-clock advertisement that leaves a lasting
									impression on consumers.
								</p>

								<p>
									Why should you care about shop signage? Picture a packed high street: What is
									going to pull a shopper away from the hustle and bustle and draw them to your
									business? Better yet, what can you create as a 24/7 advertisement with minimal
									upkeep without pulling at your purse strings? Overhead signage allows consumers to
									see you from all directions, whether it's on their daily commute to and from work
									or just popping down the road for a pint of milk, shop signage creates a
									commercial experience that your potential customers will remember.
								</p>

								<p>
									Now let&rsquo;s look at some of your options. The most successful businesses have
									their own identity, and you want your signage to align with yours. Signs are a
									great way to add a sense of luxury and exclusivity to your business and standoff
									letters provide a sleek and creativity-friendly option to represent your business
									and come in a variety of shapes sizes and colours to cater to the vibe of your
									brand. Perhaps you&rsquo;re more traditional? There are plenty of ways to
									customise your product to align with your pathos including experimenting with
									colour and texture for a fantastic result.
								</p>

								<p>
									However, price should never compromise on creativity and luckily, we offer great
									value and competitive signage to realise your vision. Two of our most popular and
									budget-friendly, overhead shop signage are flat panel and sign trays in aluminium/
									aluminium composite which are extremely diversifiable with aluminium able to be
									powder-coated in any RAL of your choosing and aluminium composite being available
									in a variety of different colours, to achieve your perfect product.
								</p>

								<p>
									It can be hard to source the perfect production team, especially in busy and
									expansive London, so we want to unload one of your burdens by telling you about
									our Yellow Signs workshop. We fabricate almost everything we produce in our
									production centre in Battersea, using our highly experienced team, meaning you
									have high-quality and excellent signage and graphics for shops and retailers in
									London, ready to be installed at your convenience.
								</p>

								<p>
									For examples of our past works, you can check out{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									If you&rsquo;re interested in decking out your business with us &ndash; using
									graphics and signage &ndash; please contact one of our signage experts at 020 7350
									1567 or email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(ShopsRetailSigns);
