import React from "react";
import WhyYouCare from "../fragments/common/WhyYouCare";
import Hero from "../fragments/product-single/Hero";
import ImageSlider from "../fragments/product-single/ImageSlider";
import ProductListing from "../fragments/product-single/ProductListing";
import Resource from "../fragments/product-single/Resource";
import { Helmet } from "react-helmet";
import { isPrerender } from "../App";

const ProductSingle = (props) => {
  return (
    <>
      {/* <Helmet>
        <title>Yellow Signs - {props?.page?.name}</title>
        <meta name="description" content={props?.page?.content} />
        <link
          rel="canonical"
          href={`${window.location.origin}${props?.page?.href}`}
        />
      </Helmet> */}
      <Hero {...props.page} />
      <ImageSlider {...props.page} />
      <WhyYouCare {...props.page} />
      {/* <Resource /> */}
      <ProductListing {...props.page} />
    </>
  );
};

export default ProductSingle;
