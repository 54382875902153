import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import device from "../common/device";
import TopMenu from "./TopMenu";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const TopWrapper = styled.div`
  background: var(--ys-teal);
`;
const MidWrapper = styled.div`
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 50%,
    rgba(8, 175, 164, 1) 50%
  );
  color: white;
  border-bottom: 2px solid var(--ys-yellow);
  @media ${device.xl} {
    .container {
      max-width: unset;
    }
  }
`;
const TopBarContent = styled.p`
  color: white;
  font-weigth: 700;
  font-size: 0.7rem;
  text-align: center;
  padding: 0.35rem 9.3rem;
  margin-bottom: 0;

  @media ${device.sm} {
    padding: 0.5rem 0;
    font-size: 0.76rem;
    text-align: center;
  }

  @media ${device.xs} {
    padding: 0.5rem 0;
    font-size: 0.65rem;
    text-align: center;
    height: 2.8rem;
  }
`;

const MobOnly = styled.div`
  z-index: 10;
  display: none;
  @media ${device.xs} {
    display: flex;
  }
`;

const DesktopOnly = styled.div`
  display: block;
  height: 100%;
  @media ${device.xs} {
    display: none;
  }
`;

const ContactWrap = styled.div`
  position: relative;
  background: var(--ys-teal);
  height: 100%;
  clip-path: polygon(5% -11%, 101% 0%, 101% 103%, 0% 100%);
  padding: 0.25rem 0rem 1rem 0;
  text-align: right;
  z-index: 4;
  @media ${device.xl} {
    clip-path: unset;
    text-align: center;
    padding: 0 0.5rem 0.5rem 0.5rem;
  }
`;

const LogoWrap = styled.div`
  background: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SocialWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: linear-gradient(
    175deg,
    rgba(255, 234, 117, 1) 11%,
    rgba(255, 216, 0, 1) 47%
  );
  height: 100%;
  clip-path: polygon(7% 0, 100% 0%, 93% 101%, 0% 101%);
  padding: 2rem 2rem 2rem 1.75rem;
  text-align: center;

  @media ${device.xl} {
    justify-content: center;
    clip-path: unset;
    padding: 0;
    width: 100%;
    margin: 0;
    text-align: right;
  }

  & svg {
    margin-top: 0.5rem;
  }
`;

// @media ${device.xl} {
//   padding: 2rem 0rem 2rem 1rem;
// };

const MenuWrapper = styled.div`
  background: var(--ys-yellow);
  margin-top: -1px;
`;

const Logo = styled.div`
  text-align: center;
  /* padding-left: 3rem; */
  @media ${device.sm} {
    padding-left: 0;
  }
  img {
    width: 23rem;
    height: auto;
    padding: 0 1.6rem;
    @media ${device.sm} {
      width: 15rem;
      padding: 1rem 1.6rem;
    }
  }
`;

const TelNo = styled.a`
  color: white !important;
  font-family: Alternate, "sans-serif";
  font-size: 2rem;
`;

const Hours = styled.div`
  font-size: 0.75rem;
  margin-top: -0.4rem;
`;

const Email = styled.a`
  font-size: 0.75rem;
  margin-top: 0rem;
  color: white !important;
  display: block;
  margin-left: 2.9rem;
  @media ${device.sm} {
    margin-left: 0;
  }
`;

const WhatsappImgGreen = styled.img`
  height: 1.5rem;
  width: auto;
  margin: 0.5rem;
`;

const SmallTxt = styled.span`
  font-size: 0.8rem;
`;

const StyledColumn = styled(Col)`
  padding: 0px !important;
  @media ${device.xl} {
    margin-left: 0;
  }
`;

const socialImgInsta = {
  color: "black",
  width: "1.5rem",
  height: "1.5rem",
};

const socialImgWhatsapp = {
  color: "black",
  width: "1.5rem",
  height: "1.5rem",
};

const socialImgFb = {
  color: "black",
  width: "1.3rem",
  height: "1.3rem",
};

class Header extends Component {
  render() {
    const dataLayer = window.dataLayer || [];
    return (
      <div>
        <TopWrapper>
          <Container>
            <Row style={{ margin: 0 }}>
              <Col>
                <TopBarContent>
                  London’s signage, graphics and large format specialist with
                  in-house production and installation, based in Battersea
                </TopBarContent>
              </Col>
            </Row>
          </Container>
        </TopWrapper>

        <MidWrapper>
          <Container>
            <Row style={{ background: "black" }}>
              <Col
                lg={2}
                style={
                  document.body.clientWidth < device.xlarge
                    ? { padding: "0" }
                    : null
                }
              >
                <DesktopOnly>
                  <SocialWrap>
                    <a
                      href="https://www.facebook.com/YellowSignsUK/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        dataLayer.push({
                          event: "facebook_click",
                          eventCategory: "social",
                          eventAction: "click",
                          eventLabel: "Facebook",
                        });
                      }}
                      aria-label="Facebook"
                    >
                      <FontAwesomeIcon
                        style={socialImgFb}
                        icon={faFacebookF}
                        className="faIcon"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/yellow_signs/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        dataLayer.push({
                          event: "instagram_click",
                          eventCategory: "social",
                          eventAction: "click",
                          eventLabel: "Instagram",
                        });
                      }}
                      aria-label="Instagram"
                    >
                      <FontAwesomeIcon
                        style={socialImgInsta}
                        icon={faInstagram}
                        className="faIcon"
                      />
                    </a>

                    <a
                      href="https://wa.me/4407539269999"
                      onClick={() => {
                        dataLayer.push({
                          event: "whatsapp_click",
                          eventCategory: "social",
                          eventAction: "click",
                          eventLabel: "WhatsApp",
                        });
                      }}
                      aria-label="Whatsapp"
                    >
                      <FontAwesomeIcon
                        style={socialImgWhatsapp}
                        icon={faWhatsapp}
                        className="faIcon"
                      />
                    </a>
                  </SocialWrap>
                </DesktopOnly>
                <MobOnly>
                  <SocialWrap
                    onClick={() => {
                      dataLayer.push({
                        event: "whatsapp_click",
                        eventCategory: "social",
                        eventAction: "click",
                        eventLabel: "WhatsApp",
                      });
                    }}
                  >
                    <a
                      href="https://wa.me/4407539269999"
                      style={{ zIndex: "10" }}
                    >
                      <SmallTxt>Whatsapp Photos</SmallTxt>
                      <WhatsappImgGreen
                        src="/img/common/whatsapp.svg"
                        alt="whatsapp logo"
                        height={50}
                        width={100}
                      />
                    </a>
                  </SocialWrap>
                </MobOnly>
              </Col>
              <Col lg={8} style={{ padding: 0 }}>
                <LogoWrap>
                  <Link to="/">
                    <Logo>
                      <img
                        draggable="false"
                        width={800}
                        height={77}
                        // style={logoImg}
                        alt="Yellow Signs Logo"
                        src={"/img/common/yellow-signs-logo.png"}
                      />
                    </Logo>
                  </Link>
                </LogoWrap>
              </Col>
              <StyledColumn
                lg={2}
                // style={{
                //   background:
                //     document.body.clientWidth < device.xlarge
                //       ? "var(--ys-teal)"
                //       : "black",
                //   padding: 0,
                // }}
              >
                <ContactWrap>
                  <TelNo
                    onClick={() => {
                      dataLayer.push({
                        event: "phone_number_click",
                        eventCategory: "contact",
                        eventAction: "click",
                        eventLabel: "Phone Number",
                      });
                    }}
                    href="tel:02073501567"
                  >
                    020 7350 1567
                  </TelNo>
                  <Hours>8am – 5pm / Mon – Fri</Hours>
                  <Email
                    onClick={() => {
                      dataLayer.push({
                        event: "email_click",
                        eventCategory: "contact",
                        eventAction: "click",
                        eventLabel: "Email",
                      });
                    }}
                    href="mailto:info@yellowsigns.co.uk"
                  >
                    info@yellowsigns.co.uk
                  </Email>
                </ContactWrap>
              </StyledColumn>
            </Row>
          </Container>
        </MidWrapper>

        <MenuWrapper>
          <Container style={{ marginTop: "-2px" }}>
            <TopMenu />
          </Container>
        </MenuWrapper>
      </div>
    );
  }
}

export default withRouter(Header);
