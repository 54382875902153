import React, { Component } from "react";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

import device from "../../fragments/common/device";
import { Helmet } from "react-helmet";

const MainContainer = styled(Container)`
  @media ${device.md} {
    margin-bottom: 8rem;
  }
`;

const MeetWrap = styled.h1`
  margin-top: 3rem;
  height: 10rem;
  background: white;
  padding-left: 2rem;
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-6deg, 0deg);
`;

const MeetLineOne = styled.span`
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
`;

const MeetLineTwo = styled.span`
  font-size: 10.3rem;
  text-transform: uppercase;
  display: block;
  font-family: Alternate, "sans-serif";
  margin-top: -2rem;
  color: var(--ys-yellow);
`;

const PersonImg = styled.img`
  position: absolute;
  width: 90%;
  filter: grayscale(100%);
`;

const Text = styled.p`
  position: absolute;
  color: white;
  background: var(--ys-black);
  width: 90%;
  padding: 1rem;
  bottom: 0;
  margin-bottom: 4.35rem;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
`;

const Name = styled.h3`
  color: var(--ys-teal);
  margin-bottom: 0;
`;

const Title = styled.p``;

const PeopleBox = (props) => {
  return (
    <Col sm={6} lg={3}>
      <BoxWrapper>
        {props.link ? (
          <a href="mailto:hr@yellowsigns.co.uk">
            <PersonImg src={props.img} alt={props.name} />
            <Text>{props.text}</Text>
          </a>
        ) : (
          <>
            <PersonImg
              width={260}
              height={260}
              src={props.img}
              alt={props.name}
            />
            <Text>{props.text}</Text>
          </>
        )}
      </BoxWrapper>
      <Name>{props.name}</Name>
      <Title>{props.title}</Title>
    </Col>
  );
};

const BoxWrapper = styled.div`
  height: 14.5rem;
  & ${PersonImg}:hover + ${Text} {
    opacity: 0.8;
  }
  & ${PersonImg}:hover {
    filter: grayscale(0%);
  }
`;

class MeetTheTeam extends Component {
  componentDidMount() {
    PointerEvents("aboutUsBtn");
  }

  render() {
    return (
      <div>
        <MainContainer>
          <Row>
            <Col>
              <MeetWrap>
                <MeetLineOne>Meet the</MeetLineOne>
                <MeetLineTwo>team</MeetLineTwo>
              </MeetWrap>
            </Col>
          </Row>
          <Row style={{ marginTop: "3rem" }}>
            <PeopleBox
              img="/img/meettheteam/david-col.jpg"
              text="When David’s not looking after customers, sales or marketing he can be found travelling the world looking for the next adventure."
              name="David"
              title="Co-Founder"
            />
            <PeopleBox
              img="/img/meettheteam/daniel-col.jpg"
              text="When Daniel’s not looking after our systems and schedules he’s kept busy with him young family, often collecting footballs from the neighbour’s back garden."
              name="Daniel"
              title="Co-Founder"
            />
            <PeopleBox
              img="/img/meettheteam/aj-col.jpg"
              text="When AJ’s not overseeing all areas of production with his eagle eye, he enjoys aquariums and marine life and making his own biltong, a true South African."
              name="AJ"
              title="Production Manager"
            />
            <PeopleBox
              img="/img/meettheteam/robert-col.jpg"
              text="When Robbie’s not conducting site surveys and installations he can be found under a car bonnet trying to get every last horsepower or decibel out of it."
              name="Robbie"
              title="Installation Manager"
            />
            <PeopleBox
              img="/img/meettheteam/joel-col.jpg"
              text="When Joel isn’t looking after our lovely clients, he can be found watching rugby or battling it out on the jujitsu mat against his latest opponent - he’s a big softy really!"
              name="Joel"
              title="Account Manager"
            />
            <PeopleBox
              img="/img/meettheteam/carl.jpg"
              text="When Carl’s not installing our latest and greatest signage creations Carl can be found with a camera in his hand or whipping up a storm in the kitchen."
              name="Carl"
              title="Installer"
            />
            <PeopleBox
              img="/img/meettheteam/borja.jpg"
              text="When Borja’s not up a ladder or scaffolding tower installing some new signs Borja can be found in an art gallery or behind a pair of DJ decks."
              name="Borja"
              title="Installer"
            />
            <PeopleBox
              img="/img/meettheteam/kaspar.jpg"
              text="When Kaspar’s not turning a new product design into reality in our workshop Kasper likes nothing more than some natural history or competitive PC gaming."
              name="Kaspar"
              title="Fabricator"
            />
            <PeopleBox
              img="/img/meettheteam/lukas-col.jpg"
              text="When Lukas isn’t creating mock-ups and visualisations he can often be found creating amazing tattoos or talking all things Japan, more often than not - ramen."
              name="Lukas"
              title="Fabricator"
            />
            <PeopleBox
              img="/img/meettheteam/will-col.jpg"
              text="When Will’s not artworking signs he’s known to have his head in the stars, not only is he keen on astronomy, he’s wild for just that, wildlife."
              name="Will"
              title="Artworker"
            />
            <PeopleBox
              img="/img/meettheteam/emily.jpg"
              text="When Emily isn’t marketing and creating social media content she can be found supporting her favourite football team Leeds or with her head in a book, as she’s an avid reader. "
              name="Emily"
              title="Marketing Assistant"
            />

            <PeopleBox
              img="/img/meettheteam/kasia-col.jpg"
              text="When Kasia’s not ensuring the admin and accounts run smoothly, she can often be found in the gym or planning her next trip away."
              name="Kasia"
              title="Accounts Administrator"
            />

            {/* <PeopleBox
							link
							img="/img/meettheteam/got-what-it-takes.jpg"
							text="Think you’ve got what it takes to work for a dynamic and progressive signage and graphics company? Don’t be shy, just email your CV to hr@yellowsigns.co.uk"
						/> */}
          </Row>
        </MainContainer>
      </div>
    );
  }
}

export default MeetTheTeam;
